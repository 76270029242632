<template>
  <div>
    <!-- Muestra los valores cuando no están expandidos -->
    <v-chip
      small
      class="ma-1"
      v-if="!isExpanded || itemData.length === 1"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ formatData(itemData[0]) | truncate(50) }}</span>
        </template>
        <span class="text-style">{{ formatData(itemData[0]) }}</span>
      </v-tooltip>
    </v-chip>

    <!-- Muestra los valores cuando están expandidos -->
    <v-chip
      v-for="(data, index) in itemData"
      :key="index"
      small
      class="ma-1"
      v-if="isExpanded"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ formatData(data) | truncate(50) }}</span>
        </template>
        <span class="text-style">{{ formatData(data) }}</span>
      </v-tooltip>
    </v-chip>

    <!-- Botón para expandir/colapsar -->
    <v-chip
      x-small
      class="ma-1"
      v-if="itemData.length > 1"
      @click="toggleExpansion"
    >
      {{ isExpanded ? '-' : '+' }} {{ itemData.length - 1 }} {{ $t('general.titles.others') }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'ShowMoreComponent',

  props: {
    item: {
      type: Object,
      required: true,
    },
    secondaryField: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isExpanded: false,
  }),

  computed: {
    itemData() {
      return this.resolveDataPath(this.item, this.secondaryField.split('.'));
    }
  },

  methods: {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    },

    resolveDataPath(obj, pathParts) {
      const result = pathParts.reduce((acc, part) => {
        if (Array.isArray(acc)) {
          return acc.map(item => item[part]).filter(Boolean);
        }
        return acc ? acc[part] : undefined;
      }, obj);

      return Array.isArray(result) ? result : [result];
    },

    formatData(data) {
      return typeof data === 'object' ? JSON.stringify(data) : data;
    }
  },

  filters: {
    truncate(text, length, clamp = '...') {
      return text.length > length ? text.slice(0, length) + clamp : text;
    }
  }
}
</script>

<style scoped>
.text-style {
  font-size: 12px;
  color: #f5f5f5;
}
</style>
