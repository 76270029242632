<template>
  <v-container fluid ref="content">
    <v-list-item three-line class="mb-0">
      <v-list-item-content>
        <div
          class="
            ma-1
            d-flex
            flex-row
            justify-space-between
            align-center align-content-center
          "
        >
          <p class="display-1 text--primary">
            {{ $t('reports.titles.general-report') }}
          </p>
          <div
            class="d-flex justify-center align-start align-content-center mr-3"
          >
            <p class="ma-0" style="width: 110px">
              {{ capitalizeFirstLetter($t('general.buttons.options')) }}
            </p>
            <UserOptions :module="'general'" :type="'REPORTS'" />
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-card class="mx-6 my-0" elevation="0" outlined>
      <v-card-text>
        <v-row class="px-lg-2 px-md-3 d-flex justify-space-between">
          <v-col cols="12" class="mr-0 pr-0">
            <v-list-item-title
              class="
                d-flex
                justify-space-between
                align-content-center align-center
              "
            >
              <div class="d-flex justify-start align-center text-filter-title">
                {{ $t('reports.titles.general-filters') }}
              </div>
            </v-list-item-title>
            <span class="pt-4">
              <v-row>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                    class="
                      d-flex
                      justify-space-between
                      align-content-center align-center
                    "
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-map-marker-radius</v-icon>
                      </v-list-item-icon>
                      {{ $t('locations.fields.name') }}
                      <strong class="red--text">*</strong>
                    </div>
                    <v-switch
                      class="ma-0 pa-0 align-center"
                      v-model="evidences.primary"
                      color="secondary"
                      hide-details
                    >
                      <template v-slot:prepend>
                        <v-label ref="label">
                          {{ $t('general.titles.evidence') }}
                        </v-label>
                      </template>
                    </v-switch>
                  </v-list-item-title>
                  <v-autocomplete
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    ref="locationsListFilters"
                    clearable
                    item-color="secondary"
                    multiple
                    item-text="name"
                    item-value="locationId"
                    v-model="primaryLocation"
                    :items="locations"
                    :loading="loadings.origin"
                    small-chips
                    color="secondary"
                    :rules="requireRules"
                    :disabled="!(locationsList && locationsList.length > 0)"

                    @click:clear="clearLocations()"
                    @change="fetchStorages(null, primaryLocation)"
                    @update:search-input="fetchLocations($event)"
                    @mouseup="
                      checkState(
                        'primary',
                        $refs.locationsListFilters.filteredItems,
                        primaryLocation
                      )
                    "
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.name | truncate(20) }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{ primaryLocation.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                        v-model="checkboxState.primary"
                        color="secondary"
                        class="pt-2 pb-2 mt-0 pl-4"
                        :hide-details="true"
                        @change="
                          primaryLocation = selectAll(
                            'primary',
                            primaryLocation,
                            $refs.locationsListFilters.filteredItems,
                            checkboxState.primary,
                            'locationId'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="secondary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width: 285px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="secondary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="item.name"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                    class="
                      d-flex
                      justify-space-between
                      align-content-center align-center
                    "
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-map-marker-radius</v-icon>
                      </v-list-item-icon>
                      {{ $t('storage.fields.name')
                      }}<strong class="red--text">*</strong>
                    </div>
                    <v-switch
                      class="ma-0 pa-0 align-center"
                      v-model="evidences.secondary"
                      color="secondary"
                      hide-details
                    >
                      <template v-slot:prepend>
                        <v-label ref="label">
                          {{ $t('general.titles.evidence') }}
                        </v-label>
                      </template>
                    </v-switch>
                  </v-list-item-title>
                  <v-autocomplete
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    ref="secondaryOriginStorageByLocationsFiltered"
                    :disabled="!(primaryLocation && primaryLocation.length > 0)"
                    multiple
                    clearable
                    item-color="secondary"
                    item-text="control_label"
                    item-value="storageDeviceId"
                    :loading="loadings.destination"
                    :items="storages"
                    v-model="secondaryLocations"
                    small-chips
                    @click:clear="fetchStorages(null, primaryLocation)"
                    @update:search-input="fetchStorages($event, primaryLocation)"
                    color="secondary"
                    :rules="requireRules"
                    return-object
                    @mouseup="
                      checkState(
                        'secondary',
                        $refs.secondaryOriginStorageByLocationsFiltered
                          .filteredItems,
                        secondaryLocations
                      )
                    "
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.control_label | truncate(20) }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{ secondaryLocations.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width: 285px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="secondary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="item.control_label"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                        v-model="checkboxState.secondary"
                        color="secondary"
                        class="pt-2 pb-2 mt-0 pl-4"
                        :hide-details="true"
                        @change="
                          secondaryLocations = selectAll(
                            'secondary',
                            secondaryLocations,
                            $refs.secondaryOriginStorageByLocationsFiltered
                              .filteredItems,
                            checkboxState.secondary,
                            'storageDeviceId'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="secondary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                    class="
                      d-flex
                      justify-space-between
                      align-content-center align-center
                    "
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-calendar</v-icon>
                      </v-list-item-icon>
                      {{ $t('records.fields.date') }}
                    </div>
                  </v-list-item-title>
                  <v-menu
                    v-model="menu"
                    ref="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        onkeypress="return (event.charCode != 34)"
                        v-on:keyup.86="replace"
                        v-on:keyup.ctrl.86="replace"
                        small
                        flat
                        solo
                        background-color="#EAEAEA80"
                        v-model="date"
                        readonly
                        v-on="on"
                        clearable
                        color="secondary"
                        append-icon="mdi-calendar"
                        @click:clear="clearDate('recordDateRef')"
                        @click:append="() => (menu = !menu)"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      color="secondary"
                      v-model="datetime"
                      range
                      :min="datetime[0]"
                      :max="today"
                      @change="dateRangeText()"
                      ref="dateRef"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        text
                        color="gray"
                        @click=";(menu = false), (date = ''), (datetime = [])"
                      >
                        {{ $t('general.buttons.clean') }}
                      </v-btn>
                      <v-btn
                        :disabled="datetime.length < 2"
                        small
                        text
                        color="secondary"
                        @click="$refs.menu.save(datetime)"
                      >
                        {{ $t('general.buttons.confirm') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </span>
          </v-col>
          <v-col cols="12" class="mr-0 pr-0">
            <v-list-item-title
              class="
                d-flex
                justify-space-between
                align-content-center align-center
              "
            >
              <div class="d-flex justify-start align-center text-filter-title">
                {{ $t('reports.titles.filters-by-module') }}
              </div>
            </v-list-item-title>
            <span class="pt-4">
              <v-row>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                    class="
                      d-flex
                      justify-space-between
                      align-content-center align-center
                    "
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-calendar-multiple-check</v-icon>
                      </v-list-item-icon>
                      {{ $t('menu.appointments') }}
                    </div>
                    <v-switch
                      class="ma-0 pa-0 align-center"
                      v-model="evidences.process"
                      color="secondary"
                      hide-details
                      :disabled="!filter.records"
                    >
                      <template v-slot:prepend>
                        <v-label ref="label">
                          {{ $t('general.titles.evidence') }}
                        </v-label>
                      </template>
                    </v-switch>
                  </v-list-item-title>
                  <v-autocomplete
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    ref="processFilters"
                    :label="$t('records.fields.process')"
                    multiple
                    clearable
                    item-color="secondary"
                    item-text="proccess_name"
                    item-value="id"
                    return-object
                    :items="orderDatas(process, 'proccess_name')"
                    v-model="process_ids"
                    small-chips
                    color="secondary"
                    @mouseup="
                      checkState(
                        'proccess',
                        $refs.processFilters.filteredItems,
                        process_ids
                      )
                    "
                    @click:clear="checkboxState.proccess = false"
                    :disabled="!filter.records"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{
                          translate(item.language_key, true) | truncate(20)
                        }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{ process_ids.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width: 360px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="secondary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="translate(item.language_key, true)"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                        v-model="checkboxState.proccess"
                        color="secondary"
                        class="pt-2 pb-2 mt-0 pl-4"
                        :hide-details="true"
                        @change="
                          process_ids = selectAll(
                            'proccess',
                            process_ids,
                            $refs.processFilters.filteredItems,
                            checkboxState.proccess,
                            'id'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="secondary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12" class="mx-auto">
                  <v-list-item-title
                    class="
                      d-flex
                      justify-space-between
                      align-content-center align-center
                    "
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-file</v-icon>
                      </v-list-item-icon>
                      {{ $t('menu.documents') }}
                    </div>
                    <v-switch
                      class="ma-0 pa-0 align-center"
                      v-model="evidences.documents"
                      color="secondary"
                      hide-details
                      :disabled="!filter.documents"
                    >
                      <template v-slot:prepend>
                        <v-label ref="label">
                          {{ $t('general.titles.evidence') }}
                        </v-label>
                      </template>
                    </v-switch>
                  </v-list-item-title>
                  <v-autocomplete
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    ref="documentTypesFilters"
                    :label="$t('documents.fields.type')"
                    multiple
                    clearable
                    item-color="secondary"
                    item-value="id"
                    item-text="document_type_name"
                    :items="orderDatas(documentTypes, 'document_type_name')"
                    @change="documentDescriptionFiltered(document_type_ids)"
                    @click:clear="clearDocumentType()"
                    v-model="document_type_ids"
                    small-chips
                    color="secondary"
                    @mouseup="
                      checkState(
                        'document_type',
                        $refs.documentTypesFilters.filteredItems,
                        document_type_ids
                      )
                    "
                    :disabled="!filter.documents"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{
                          item.document_type_name | truncate(20)
                        }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{ document_type_ids.length - 1 }}
                        {{ $t('general.titles.others') }})
                      </span>
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width: 285px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="secondary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="item.document_type_name"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                        v-model="checkboxState.document_type"
                        color="secondary"
                        class="pt-2 pb-2 mt-0 pl-4"
                        :hide-details="true"
                        @change="
                          document_type_ids = selectAll(
                            'document_type',
                            document_type_ids,
                            getDescriptionsType(
                              $refs.documentTypesFilters.filteredItems
                            ),
                            checkboxState.document_type,
                            'id'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="secondary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2" />
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    ref="documentDescriptionFilters"
                    :label="$t('documents.fields.description')"
                    :loading="loadingDescription"
                    :disabled="
                      !(document_type_ids && document_type_ids.length > 0) ||
                      !filter.documents
                    "
                    multiple
                    clearable
                    item-color="secondary"
                    item-value="id"
                    item-text="document_description_name"
                    return-object
                    :items="
                      orderDatas(
                        documentDescriptions,
                        'document_description_name'
                      )
                    "
                    v-model="document_description_ids"
                    small-chips
                    color="secondary"
                    @mouseup="
                      checkState(
                        'document_description',
                        $refs.documentDescriptionFilters.filteredItems,
                        document_description_ids
                      )
                    "
                    @click:clear="checkboxState.document_description = false"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{
                          translate(item.language_key) | truncate(20)
                        }}</span>
                      </v-chip>
                      <span
                        v-if="index === 1"
                        class="grey&#45;&#45;text caption"
                        >(+{{ document_description_ids.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width: 285px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="secondary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="translate(item.language_key)"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                        v-model="checkboxState.document_description"
                        color="secondary"
                        class="pt-2 pb-2 mt-0 pl-4"
                        :hide-details="true"
                        @change="
                          document_description_ids = selectAll(
                            'document_description',
                            document_description_ids,
                            $refs.documentDescriptionFilters.filteredItems,
                            checkboxState.document_description,
                            'id'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="secondary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2" />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                    class="
                      d-flex
                      justify-space-between
                      align-content-center align-center
                    "
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-order-bool-ascending-variant</v-icon>
                      </v-list-item-icon>
                      {{ $t('menu.inspections') }}
                    </div>
                    <v-switch
                      class="ma-0 pa-0 align-center"
                      v-model="evidences.template"
                      color="secondary"
                      hide-details
                      :disabled="!filter.inspections"
                    >
                      <template v-slot:prepend>
                        <v-label ref="label">
                          {{ $t('general.titles.evidence') }}
                        </v-label>
                      </template>
                    </v-switch>
                  </v-list-item-title>
                  <v-autocomplete
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    ref="allTemplatesFiters"
                    color="secondary"
                    :label="$t('survey.fields.name')"
                    clearable
                    multiple
                    item-color="secondary"
                    :items="orderDatas(allTemplates, 'name')"
                    item-text="name"
                    item-value="name"
                    v-model="template_names"
                    small-chips
                    @mouseup="
                      checkState(
                        'name',
                        $refs.allTemplatesFiters.filteredItems,
                        template_names
                      )
                    "
                    @click:clear="checkboxState.name = false"
                    :disabled="!filter.inspections"
                  >
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width: 360px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="secondary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="item.name"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.name | truncate(20) }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{ template_names.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                        v-model="checkboxState.name"
                        color="secondary"
                        class="pt-2 pb-2 mt-0 pl-4"
                        :hide-details="true"
                        @change="
                          template_names = selectAll(
                            'name',
                            template_names,
                            getByProperty(
                              $refs.allTemplatesFiters.filteredItems,
                              'name'
                            ),
                            checkboxState.name,
                            'name'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="secondary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="ifRecordsFound"
            cols="8"
            class="px-md-3 d-flex flex-column justify-start align-start"
          >
            <p class="pl-2 pa-0 ma-0" style="font-size: 20px; font-weight: 600">
              {{ $t('general.titles.totals') }}:
            </p>
            <div class="d-flex justify-space-between align-center pl-2">
              <p
                v-if="totalRecordsFromServer"
                class="pa-0 ma-0 pr-2"
                style="font-size: 18px"
              >
                <strong>{{ $t('menu.appointments') }}:</strong>
                {{ totalRecordsFromServer }}
              </p>
              <p
                v-if="totalInspectionsFromServer"
                class="pa-0 ma-0 pr-2"
                style="font-size: 18px"
              >
                <strong>{{ $t('menu.inspections') }}:</strong>
                {{ totalInspectionsFromServer }}
              </p>
              <p
                v-if="totalDocumentsFromServer"
                class="pa-0 ma-0 pr-2"
                style="font-size: 18px"
              >
                <strong>{{ $t('menu.documents') }}:</strong>
                {{ totalDocumentsFromServer }}
              </p>
            </div>
          </v-col>
          <v-col
            :cols="ifRecordsFound ? 4 : 12"
            class="px-md-3 d-flex justify-end align-end align-content-end"
          >
            <v-btn
              small
              tile
              :elevation="0"
              color="#d8d8d896"
              style="border-radius: 1.5em"
              @click="searchData"
              :loading="loadingReport"
              class="ma-1"
              :disabled="
                !(
                  primaryLocation &&
                  secondaryLocations &&
                  secondaryLocations.length > 0
                )
              "
            >
              <template v-slot:loader>
                <v-progress-circular
                    style="position: absolute; height: 15px"
                    color="secondary accent-4"
                    indeterminate
                    rounded
                />
              </template>
              <v-icon small left>mdi-magnify</v-icon>
              {{ $t('reports.buttoms.search') }}
            </v-btn>
            <v-btn
              small
              tile
              :elevation="0"
              color="#d8d8d896"
              style="border-radius: 1.5em"
              @click="showModalExport = !showModalExport"
              :disabled="
                !(
                  records.length > 0 ||
                  inspections.length > 0 ||
                  documents.length > 0
                ) || loadingReport
              "
              class="ma-1"
            >
              <v-icon small left>mdi-download-multiple</v-icon>
              {{ $t('reports.buttoms.traceability') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="ma-3 mt-10">
      <v-col cols="12" class="ma-0 pa-0">
        <v-list-item-title
          class="
            display-1
            ml-3
            d-flex
            justify-start
            align-content-center align-center
          "
        >
          {{ $t('menu.appointments') }}
        </v-list-item-title>
        <v-data-table
          :headers="headers.records"
          :items="records"
          :loading="loading.records"
          :options.sync="optionsR"
          v-model="selected.records"
          :show-select="showCheckBoxRecords"
          :no-data-text="$t('general.ui.not_data_found')"
          :loading-text="$t('general.notification.loadData')"
          :server-items-length="totalRecordsFromServer"
          :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
        >
          <template v-slot:top>
            <v-row align="center" justify="space-between">
              <v-col lg="4" md="4" sm="12" xl="4">
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 1.5em"
                  @click.native="checkBoxShow('records')"
                  class="mr-1"
                  :disabled="loading.records || !(records.length > 0)"
                >
                  <span v-if="!showCheckBoxRecords">
                    <v-icon small color="#6D6D6D" left
                      >mdi-checkbox-multiple-marked-outline</v-icon
                    >
                    <span>{{ $t('general.buttons.checksBoxEnable') }}</span>
                  </span>
                  <span v-else>
                    <v-icon small color="#6D6D6D" left
                      >mdi-checkbox-multiple-marked</v-icon
                    >
                    <span>{{ $t('general.buttons.checksBoxDisable') }}</span>
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex justify-end align-center">
                {{ $t('reports.fields.export') }}
                <v-switch
                  color="secondary"
                  class="ml-2"
                  v-model="filter.records"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.sdi_origin="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" small
                  >{{
                    (item.sdi_origin.primaryLocation.name +
                      ' | ' +
                      translate(item.sdi_origin.control_label))
                      | truncate(20)
                  }}
                </v-chip>
              </template>
              <span style="font-size: 12px; color: #f5f5f5">
                {{
                  item.sdi_origin.primaryLocation.name +
                  ' | ' +
                  translate(item.sdi_origin.control_label)
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.sdi_destination="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" small
                  >{{
                    (item.sdi_destination.primaryLocation.name +
                      ' | ' +
                      translate(item.sdi_destination.control_label))
                      | truncate(20)
                  }}
                </v-chip>
              </template>
              <span style="font-size: 12px; color: #f5f5f5">
                {{
                  item.sdi_destination.primaryLocation.name +
                  ' | ' +
                  translate(item.sdi_destination.control_label)
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.uniqueIdRepresentation="{ item }">
            <UniqueIdRepresentationComponent :unique-id="item.uniqueIdRepresentation" />
          </template>
          <template v-slot:item.record_date_time="{ item }">
            <span>{{ getDateFormat(item.record_date_time) }}</span>
          </template>
          <template v-slot:item.sdi_origin_name="{ item }">
            <v-chip class="ma-1" small>{{
              item.sdi_origin.primaryLocation.name
            }}</v-chip>
          </template>
          <template v-slot:item.sdi_destination_name="{ item }">
            <v-chip class="ma-1" small>{{
              item.sdi_destination.primaryLocation.name
            }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="neuter"
                  small
                  class="mr-2"
                  @click="showRecord(item)"
                >
                  mdi-eye-outline
                </v-icon>
              </template>
              <span>
                {{ $t('general.buttons.show') }}
                <span style="font-size: 12px; color: #aaaaaa">
                  {{ $t('menu.records') }}
                </span>
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <v-list-item-title
          class="
            display-1
            ml-3
            d-flex
            justify-start
            align-content-center align-center
          "
        >
          {{ $t('menu.inspections') }}
        </v-list-item-title>
        <v-data-table
          :headers="headers.inspections"
          :items="inspections"
          :loading="loading.inspections"
          :options.sync="optionsI"
          :show-select="showCheckBoxInspections"
          v-model="selected.inspections"
          :no-data-text="$t('general.ui.not_data_found')"
          :loading-text="$t('general.notification.loadData')"
          :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
        >
          <template v-slot:top>
            <v-row align="center" justify="space-between">
              <v-col lg="4" md="4" sm="12" xl="4">
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 1.5em"
                  @click.native="checkBoxShow('inspections')"
                  class="mr-1"
                  :disabled="loading.inspections || !(inspections.length > 0)"
                >
                  <span v-if="!showCheckBoxInspections">
                    <v-icon small color="#6D6D6D" left
                      >mdi-checkbox-multiple-marked-outline</v-icon
                    >
                    <span>{{ $t('general.buttons.checksBoxEnable') }}</span>
                  </span>
                  <span v-else>
                    <v-icon small color="#6D6D6D" left
                      >mdi-checkbox-multiple-marked</v-icon
                    >
                    <span>{{ $t('general.buttons.checksBoxDisable') }}</span>
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex justify-end align-center">
                {{ $t('reports.fields.export') }}
                <v-switch
                  color="secondary"
                  class="ml-2"
                  v-model="filter.inspections"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.surveyPercentPunctuation="{ item }">
            <h3 v-if="item.surveyPercentPunctuation > 0">
              <span>{{ item.surveyPercentPunctuation | round(1) }}%</span>
            </h3>
            <span v-else>
              <v-chip small disabled>
                {{ $t('inspections.labels.empty_answer') }}
              </v-chip>
            </span>
          </template>
          <template v-slot:item.location_name="{ item }">
            <ShowMoreComponent :item="item" secondary-field="locations" />
          </template>
          <template v-slot:item.personInCharge="{ item }">
            <span>{{ item.personInCharge }}</span>
          </template>
          <template v-slot:item.data="{ item }">
            <span>{{ getDateFormat(item.data) }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="neuter"
                  small
                  class="mr-2"
                  @click="showInspection(item)"
                >
                  mdi-eye-outline
                </v-icon>
              </template>
              <span>
                {{ $t('general.buttons.show') }}
                <span style="font-size: 12px; color: #aaaaaa">
                  {{ $t('menu.inspections') }}
                </span>
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <v-list-item-title
          class="
            display-1
            ml-3
            d-flex
            justify-start
            align-content-center align-center
          "
        >
          {{ $t('menu.documents') }}
        </v-list-item-title>
        <v-data-table
          :headers="headers.documents"
          :items="documents"
          :loading="loading.documents"
          :options.sync="optionsD"
          :show-select="showCheckBoxDocuments"
          v-model="selected.documents"
          :no-data-text="$t('general.ui.not_data_found')"
          :loading-text="$t('general.notification.loadData')"
          :server-items-length="totalDocumentsFromServer"
          :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
        >
          <template v-slot:top>
            <v-row align="center" justify="space-between">
              <v-col lg="4" md="4" sm="12" xl="4">
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 1.5em"
                  @click.native="checkBoxShow('documents')"
                  class="mr-1"
                  :disabled="loading.documents || !(documents.length > 0)"
                >
                  <span v-if="!showCheckBoxDocuments">
                    <v-icon small color="#6D6D6D" left
                      >mdi-checkbox-multiple-marked-outline</v-icon
                    >
                    <span>{{ $t('general.buttons.checksBoxEnable') }}</span>
                  </span>
                  <span v-else>
                    <v-icon small color="#6D6D6D" left
                      >mdi-checkbox-multiple-marked</v-icon
                    >
                    <span>{{ $t('general.buttons.checksBoxDisable') }}</span>
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex justify-end align-center">
                {{ $t('reports.fields.export') }}
                <v-switch
                  color="secondary"
                  class="ml-2"
                  v-model="filter.documents"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.authority_name="{ item }">
            <span>{{ item.authority_name }}</span>
          </template>
          <template v-slot:item.emission="{ item }">
            <span>{{ item.emission }}</span>
          </template>
          <template v-slot:item.validade="{ item }">
            <span>{{ item.validade }}</span>
          </template>
          <template v-slot:item.storage_devices="{ item }">
            <ShowMoreComponent :item="item" secondary-field="storageDevices" />
          </template>
          <template v-slot:item.observations="{ item }">
            <span v-if="item.observations">
              <read-more
                  :text="item.observations"
                  :max-chars="100"
                  :font-size="14"
              />
            </span>
            <span v-else>
              <v-chip small disabled>
                N/A
              </v-chip>
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="neuter"
                  small
                  class="mr-2"
                  @click="showDocument(item)"
                >
                  mdi-eye-outline
                </v-icon>
              </template>
              <span>
                {{ $t('general.buttons.show') }}
                <span style="font-size: 12px; color: #aaaaaa">
                  {{ $t('menu.documents') }}
                </span>
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Export PDF -->
    <v-row justify="center">
      <v-dialog v-model="showModalExport" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{
            $t('reports.titles.exportConfigPDF')
          }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :no-data-text="$t('general.fields.noDataAvailable')"
                  color="secondary"
                  :label="$t('reports.titles.orientation')"
                  item-color="secondary"
                  :items="orderDatas(pageEnum, 'text')"
                  item-value="type"
                  item-text="text"
                  v-model="orientation"
                  small-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              color="gray"
              @click="closeDialog()"
              :disabled="exportLoading"
            >
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn
              small
              text
              color="secondary"
              :disabled="!orientation"
              :loading="exportLoading"
              @click="exportRecords()"
            >
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Dialog MORE DETAILS - RECORDS -->
    <v-row justify="center">
      <v-dialog
        persistent
        v-model="showRecordDialog"
        max-width="800px"
        scrollable
      >
        <v-card light color="white">
          <v-card-title class="d-flex justify-space-between px-14">
            <v-toolbar fixed flat>
              <span
                class="headline"
                style="
                  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
                  font-weight: bold !important;
                  color: rgba(35, 35, 35, 0.96) !important;
                "
                >{{ $t('general.titles.details') }}</span
              >
              <span v-if="recordSelected && recordSelected.length > 0">
                {{ recordSelected.person_in_charge.name }}
              </span>
            </v-toolbar>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-container>
              <v-row v-if="showRecordDialog">
                <div class="d-flex flex-wrap">
                  <v-card elevation="0" width="100%" class="mt-1 mr-4">
                    <v-card-title
                      class="pb-1 pt-1"
                      style="background-color: rgba(3, 172, 140, 0.21)"
                    >
                      <span style="color: #33a387; font-size: 18px">{{
                        $t('records.label.titles.registryData')
                      }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding-top: 0 !important;
                      "
                    >
                      <v-card elevation="0" width="100%" class="mb-3">
                        <v-card-text
                          style="
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            padding-top: 0 !important;
                          "
                        >
                          <div
                            class="d-flex flex-wrap mb-2 pa-3"
                            style="
                              background-color: rgba(3, 172, 140, 0.04);
                              border-radius: 3px;
                            "
                          >
                            <div
                              style="width: 200px; padding: 5px"
                              v-for="(values, i) in headers.records"
                              :key="i"
                            >
                              <p
                                class="black--text mb-0"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 14px;
                                  font-weight: 400;
                                "
                              >
                                {{ values ? values.text : '' }}:
                              </p>
                              <p
                                class="black--text mb-2"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 16px;
                                  font-weight: 600;
                                "
                                v-if="values.value !== 'uniqueIdRepresentation'"
                              >
                                {{ getDetailsMainData(values.value) || '-' }}
                              </p>
                              <UniqueIdRepresentationComponent v-else :unique-id="recordsCompanyById.uniqueIdRepresentation" format-style="raw" styles="font-size: 16px; color: black" :formated="true" />
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                    <v-divider />
                  </v-card>
                  <div
                    style="width: 250px"
                    v-if="existStructures"
                    v-for="item in getStructures"
                    :key="item.id"
                  >
                    <div
                      style="
                        background-color: rgba(3, 172, 140, 0.02);
                        border-radius: 3px;
                      "
                      class="ma-2 pa-2"
                    >
                      <p
                        class="black--text mb-0"
                        style="
                          font-family: 'Open Sans', 'Helvetica Neue', Arial,
                            Helvetica, sans-serif;
                          font-size: 14px;
                          font-weight: 400;
                        "
                      >
                        {{ translate(item.structure.language_key, true) }}:
                      </p>
                      <p
                        class="black--text mb-0"
                        style="
                          font-family: 'Open Sans', 'Helvetica Neue', Arial,
                            Helvetica, sans-serif;
                          font-size: 16px;
                          font-weight: 600;
                        "
                        v-if="
                          typeof item.recordStructureValue.record_value ===
                          'object'
                        "
                        v-for="(structureName, i) in item.recordStructureValue
                          .record_value"
                        :key="i"
                      >
                        {{ getValue(item.structure, structureName) }}
                      </p>
                      <p
                        class="black--text"
                        v-if="
                          !item.recordStructureValue.record_value ||
                          typeof item.recordStructureValue.record_value !==
                            'object'
                        "
                      >
                        {{
                          getValue(
                            item.structure,
                            item.recordStructureValue.record_value
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <v-card
                    elevation="0"
                    width="100%"
                    class="mt-1 mr-4 overflow-y-auto"
                    max-height="400px"
                    v-for="(group, index) in getGroups"
                    :key="index"
                    v-if="existGroups"
                  >
                    <v-card-title
                      class="pb-1 pt-1"
                      style="background-color: rgba(3, 172, 140, 0.21)"
                    >
                      <span style="color: #33a387; font-size: 18px">{{
                        translate(group.languageKey, true).toUpperCase()
                      }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding-top: 0 !important;
                      "
                    >
                      <v-card
                        elevation="0"
                        width="100%"
                        class="mb-3"
                        v-for="row in group.rows"
                        v-bind:key="row.groupRow"
                      >
                        <v-card-text
                          style="
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            padding-top: 0 !important;
                          "
                        >
                          <div
                            class="d-flex flex-wrap mb-2 pa-3"
                            style="
                              background-color: rgba(3, 172, 140, 0.04);
                              border-radius: 3px;
                            "
                          >
                            <div
                              style="width: 200px"
                              v-for="values in row.values"
                            >
                              <p
                                class="black--text mb-0"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 14px;
                                  font-weight: 400;
                                "
                              >
                                {{
                                  values
                                    ? translate(
                                        values.structure.language_key,
                                        true
                                      )
                                    : ''
                                }}:
                              </p>
                              <p
                                class="black--text mb-2"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 16px;
                                  font-weight: 600;
                                "
                                v-for="(item, index) in values
                                  .recordStructureValue.record_value"
                                :key="index"
                              >
                                {{ getValue(values.structure, item) }}
                              </p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-4">
            <v-spacer />
            <v-btn small text color="secondary" @click="closeDetails('record')">
              {{ $t('general.buttons.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Dialog MORE DETAILS - INSPECTIONS -->
    <v-row justify="center">
      <v-dialog
        persistent
        v-model="showInspectionDialog"
        width="800px"
        scrollable
      >
        <v-card light color="white">
          <v-card-title class="d-flex justify-space-between px-14">
            <v-toolbar fixed flat>
              <span
                class="headline"
                style="
                  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
                  font-weight: bold !important;
                  color: rgba(35, 35, 35, 0.96) !important;
                "
                >{{ $t('general.titles.details') }}</span
              >
            </v-toolbar>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-container style="display: contents" class="ma-0">
              <v-row justify="space-between" v-if="showInspectionDialog">
                <v-col cols="12">
                  <div class="d-flex flex-wrap">
                    <div style="width: 250px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('survey.fields.name') }}:
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          {{ inspectionSelected.name }}
                        </p>
                      </div>
                    </div>
                    <div style="width: 250px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('survey.titles.punctuationSection') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          <span class="font-weight-black" style="color: #03ac8c"
                            >{{
                              inspectionSelected.surveyAnswerPunctuation
                                | round(1)
                            }}%</span
                          >
                        </p>
                      </div>
                    </div>
                    <div style="width: 250px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('survey.fields.date') }}:
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          {{ getDateFormat(inspectionSelected.data) }}
                        </p>
                      </div>
                    </div>

                    <div style="width: 250px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('documents.fields_add.storage_device') + ': ' }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                          v-for="(local, index) in inspectionSelected.locations"
                          :key="index"
                        >
                          {{ local.control_label }}
                        </p>
                      </div>
                    </div>
                    <!--                                        <v-img :lazy-src="documentSelected.s3_url" contain :width="width" min-width="500" :max-width="width" :src="`${documentSelected.s3_url}`" />-->
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-card
                    class="mx-auto mt-10 pa-3"
                    flat
                    style="
                      background-color: rgba(218, 218, 218, 0.13);
                      border: none;
                      border-radius: 10px;
                    "
                    v-for="(
                      sectionValues, i
                    ) in inspectionSelected.questionSectionList"
                    :key="i"
                  >
                    <v-card-title
                      class="d-flex align-start"
                      style="border-radius: 8px"
                    >
                      <v-list-item-content class="d-block text-left">
                        <div class="d-block">
                          <v-list-item-title
                            style="
                              font-weight: bold;
                              font-size: 20px;
                              color: #03ac8c;
                            "
                          >
                            {{ sectionValues['name'] }}
                          </v-list-item-title>
                          <v-list-item-title
                            style="
                              font-size: 15px;
                              color: #131313;
                              font-weight: 300;
                            "
                          >
                            {{ $t('survey.titles.punctuationSection') }}
                            <strong>{{
                              sectionValues.questionSectionAnswerPunctuation
                                | round(2)
                            }}</strong>
                            {{ $t('survey.titles.punctuationSection1') }}
                            <strong>{{ sectionValues.punctuation }}</strong>
                            {{ $t('survey.titles.punctuationSection2') }}
                          </v-list-item-title>
                        </div>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <span
                        style="
                          font-weight: bold;
                          font-size: 25px;
                          color: rgb(3, 172, 140);
                          display: flex;
                          align-items: flex-end;
                          flex-direction: column;
                          text-align: center;
                        "
                      >
                        <span
                          style="
                            font-size: 10px;
                            opacity: 50%;
                            margin-top: -5px;
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <span style="font-size: 30px"
                                  >{{
                                    sectionAverage(sectionValues) | round(1)
                                  }}%</span
                                >
                              </span>
                            </template>
                            <span
                              >{{ sectionAverage(sectionValues) | round(1) }}%
                              {{ $t('inspections.labels.complete_of') }}
                              100%</span
                            >
                          </v-tooltip>
                        </span>

                        <v-tooltip bottom open-on-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              outlined
                              text
                              color="secondary"
                              class="mt-1"
                            >
                              {{ $t('inspections.labels.responsable') }}
                            </v-btn>
                          </template>
                          <span
                            v-for="(
                              responsable, index
                            ) in sectionValues.responsables"
                            :key="index"
                          >
                            {{ responsable.name }}
                            <br />
                          </span>
                        </v-tooltip>
                      </span>
                    </v-card-title>
                    <div
                      style="
                        background-color: rgba(3, 172, 140, 0.02);
                        border-radius: 3px;
                      "
                      class="ma-2 pa-2"
                      v-for="(item, index) in sectionValues.questions"
                      v-bind:key="index"
                      v-if="item.show === true || !item.extraQuestionFatherRef"
                    >
                      <p
                        class="black--text mb-0"
                        style="
                          font-family: 'Open Sans', 'Helvetica Neue', Arial,
                            Helvetica, sans-serif;
                          font-size: 14px;
                          font-weight: 400;
                        "
                      >
                        <read-more
                          :classes="'mb-0'"
                          :text="item.question"
                          :max-chars="50"
                          :font-size="20"
                        />
                      </p>
                      <p
                        class="black--text mb-0"
                        style="
                          font-family: 'Open Sans', 'Helvetica Neue', Arial,
                            Helvetica, sans-serif;
                          font-size: 16px;
                          font-weight: 600;
                        "
                      >
                        <span v-if="item.answers && item.answers.length > 0">
                          <span
                            v-if="
                              transformAnswers(item, item.answers) &&
                              transformAnswers(item, item.answers) !== 'null' &&
                              transformAnswers(item, item.answers) !== ''
                            "
                            class="d-flex pt-5 pb-5"
                          >
                            <read-more
                              :classes="'mb-0'"
                              :text="transformAnswers(item, item.answers)"
                              :max-chars="50"
                              :font-size="20"
                            />
                          </span>
                          <span v-else>
                            <v-chip small disabled>
                              {{ $t('inspections.labels.empty_answer') }}
                            </v-chip>
                          </span>
                          <span class="mt-10" v-else>
                            <v-chip small disabled>
                              {{ $t('inspections.labels.empty_answer') }}
                            </v-chip>
                          </span>
                        </span>
                      </p>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-4">
            <v-spacer />
            <v-btn
              small
              text
              color="secondary"
              @click="closeDetails('inspection')"
            >
              {{ $t('general.buttons.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Dialog MORE DETAILS - DOCUMENTS -->
    <v-row justify="center">
      <v-dialog
        persistent
        v-model="showDocumentDialog"
        :max-width="isImageOrPdf ? '1024px' : '800px'"
        scrollable
      >
        <v-card light color="white">
          <v-card-title class="d-flex justify-space-between">
            <v-toolbar fixed flat>
              <span
                class="headline"
                style="
                  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
                  font-weight: bold !important;
                  color: rgba(35, 35, 35, 0.96) !important;
                "
              >{{ $t('general.titles.details') }}</span
              >
              <v-spacer />
              <div class="d-flex justify-center align-center">
                <p class="ma-0 pa-0 mr-2" style="font-size: 16px">{{ $t('documents.fields.visibility') }}: </p>
                <span class="visibility" v-if="documentSelected.externalVisibility">
                  {{ $t('documents.labels.options.visible') }}
                </span>
                <span class="not-visibility" v-else>
                  {{ $t('documents.labels.options.notVisible') }}
                </span>
              </div>

            </v-toolbar>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-container style="display: contents" class="ma-0">
              <v-row justify="space-between" v-if="showDocumentDialog">
                <v-col :cols="isImageOrPdf ? 5 : 12">
                  <div
                    :class="
                      isImageOrPdf
                        ? 'd-flex flex-column flex-wrap'
                        : 'd-flex flex-wrap'
                    "
                  >
                    <div style="width: 400px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('documents.fields.authority') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          {{ documentSelected.documentAuthority.name }}
                        </p>
                      </div>
                    </div>
                    <div style="width: 400px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('documents.fields.type') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          {{
                            translate(
                              documentSelected.documentType.language_key,
                              true
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div style="width: 400px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('documents.fields.description') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          {{
                            translate(
                              documentSelected.documentDescription.language_key,
                              true
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div style="width: 400px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('documents.fields.document_date') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          {{ getDateFormat(documentSelected.document_date) }}
                        </p>
                      </div>
                    </div>
                    <div style="width: 400px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('documents.fields.expiration_date') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          <span v-if="documentSelected.expiration_date && documentSelected.expiration_date !== '-'">
                            {{ getDateFormat(documentSelected.expiration_date) }}
                          </span>
                          <v-chip v-else disabled small>
                            N/A
                          </v-chip>
                        </p>
                      </div>
                    </div>
                    <div style="width: 400px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('reports.titles.localizations') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                          v-for="(
                            storage, index
                          ) in documentSelected.storage_device_names"
                          :key="index"
                        >
                          {{ storage }}
                        </p>
                      </div>
                    </div>
                    <div style="width: 400px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('documents.fields.season') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          {{ documentSelected.season ? translate(documentSelected.season.languageKey) : '-' }}
                        </p>
                      </div>
                    </div>
                    <div style="width: 400px">
                      <div
                        style="
                          background-color: rgba(3, 172, 140, 0.02);
                          border-radius: 3px;
                        "
                        class="ma-2 pa-2"
                      >
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ $t('documents.fields.public') }}
                        </p>
                        <p
                          class="black--text mb-0"
                          style="
                            font-family: 'Open Sans', 'Helvetica Neue', Arial,
                              Helvetica, sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                          "
                        >
                          {{ documentSelected.isPublic ? $t('documents.labels.options.visible') : $t('documents.labels.options.notVisible') }}
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col
                  v-if="isImageOrPdf"
                  cols="7"
                  class="d-flex justify-space-between"
                >
                  <v-card
                    :light="true"
                    outlined
                    :width="imageWidth"
                    min-width="520"
                    :max-width="imageWidth"
                    elevation="0"
                    color="white"
                  >
                    <v-card-text>
                      <v-container
                        v-if="
                          ['jpg', 'png', 'jpeg'].includes(
                            documentSelected.file_extension
                          )
                        "
                      >
                        <v-row justify="center">
                          <v-container class="overflow-x-auto relative">
                            <v-img
                              :lazy-src="documentSelected.s3_url"
                              contain
                              :aspect-ratio="16/9"
                              :width="imageWidth"
                              min-width="400"
                              :max-width="imageWidth"
                              :src="`${documentSelected.s3_url}`"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="secondary"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-container>
                        </v-row>
                        <v-row justify="center">
                          <v-btn x-large icon @click="zoomLess">
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                          <v-btn x-large icon @click="zoomPlus">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                      <v-container
                        v-else-if="documentSelected.file_extension === 'pdf'"
                      >
                        <v-row justify="center">
                          <pdf
                            :src="`${documentSelected.s3_url}`"
                            @num-pages="pageCount = $event"
                            @page-loaded="currentPage = $event"
                            :page="currentPage"
                            style="width: 60%"
                          />
                        </v-row>
                        <v-row justify="center">
                          <v-btn
                            small
                            class="mr-2"
                            x-large
                            icon
                            @click="backPage"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                          <span class="title pt-2"
                          >{{ currentPage }} / {{ pageCount }}</span
                          >
                          <v-btn
                            small
                            class="ml-2"
                            x-large
                            icon
                            @click="nextPage"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="documentSelected.observations">
                <v-col cols="12">
                  <v-card-title style="color: black;">
                    {{ $t('documents.fields.observations') }}
                  </v-card-title>
                  <v-card-subtitle style="color: black;">
                    {{ documentSelected.observations }}
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-4">
            <v-spacer />
            <v-btn
              small
              text
              color="secondary"
              @click="closeDetails('document')"
            >
              {{ $t('general.buttons.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'
  import _ from 'lodash'
  import LanguajeService from '../../services/LanguajeService'
  import ReadMore from '../../components/ReadMoreComponent'
  import moment from 'moment'
  import axios from 'axios'
  import pdf from 'vue-pdf'
  import sortList from '@/services/OrderListService'
  import UserOptions from '../../components/UserOptions/IndexComponent'
  import ShowMoreComponent from "../../components/ShowMoreComponent";
  import { generalMixin } from '../../mixins/general.mixin'
  import UniqueIdRepresentationComponent from "@/module/records/components/UniqueIdRepresentationComponent.vue"

  const STRUCTURE_TYPE = {
    MULTIPLE: [
      'PRODUCT',
      'EQUIPMENT',
      'OPERATOR',
      'INPUT',
      'MEASUREMENTUNIT',
      'SEASON',
      'LIST',
    ],
    SIMPLE: [
      'NUMBER',
      'TEXT',
      'LOTE',
      'IMO',
      'LACRE',
      'TIME',
      'DATA',
      'DATATIME',
      'PRODUCTSIMPLE',
      'EQUIPMENTSIMPLE',
      'OPERATORSIMPLE',
      'INPUTSIMPLE',
      'MEASUREMENTUNITSIMPLE',
      'SEASONSIMPLE',
      'LISTSIMPLE',
    ],
  }

  const PROFILE = JSON.parse(localStorage.getItem('profile'));

  export default {
    components: { UniqueIdRepresentationComponent, ShowMoreComponent, UserOptions, ReadMore, pdf },
    mixins: [generalMixin],
    data: () => ({
      today: null,
      checkboxState: {
        primary: false,
        secondary: false,
        proccess: false,
        document_type: false,
        document_description: false,
        name: false,
      },
      profile: null,
      exportLoading: false,
      showDialog: false,
      showRecordDialog: false,
      showInspectionDialog: false,
      showDocumentDialog: false,
      showModalExport: false,
      loadingReport: false,
      loading: {
        records: false,
        inspections: false,
        documents: false,
      },
      imageWidth: 400,
      loadingDescription: false,
      primaryLocation: [],
      secondaryLocations: [],
      document_type_ids: [],
      document_description_ids: [],
      process_ids: [],
      template_names: [],
      date: '',
      datetime: [],
      menu: false,
      evidences: {
        primary: true,
        secondary: true,
        documents: true,
        process: true,
        template: true,
      },
      loadings: {
        origin: false,
        destination: false
      },
      defaultFilters: {
        companyId: PROFILE.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 15,
          sortBy: 'id',
          direction: 'ASC',
        }
      },
      filters: {
        records: {
          pageableDTO: null,
          process_ids: [],
        },
        inspections: {
          pageableDTO: null,
          template_names: [],
          surveyStateType: null,
        },
        documents: {
          pageableDTO: null,
          document_type_ids: [],
          document_description_ids: [],
        },
      },
      filter: {
        records: true,
        inspections: true,
        documents: true,
      },
      selected: {
        records: [],
        inspections: [],
        documents: [],
      },
      showCheckBoxRecords: false,
      showCheckBoxInspections: false,
      showCheckBoxDocuments: false,
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      optionsR: {},
      optionsI: {},
      optionsD: {},
      headers: {
        records: [
          {
            text: i18n.t("records.fields.code"),
            value: "uniqueIdRepresentation",
            val: "uniqueIdRepresentation",
            sortable: true,
            width: 150,
          },
          {
            text: i18n.t('pre-records.fields.stage'),
            value: 'sdt_to_show_translate',
            val: 'process.sdt_to_show',
            sortable: false,
            type: 'text',
            width: 150,
          },
          {
            text: i18n.t('records.fields.origin'),
            value: 'sdi_origin',
            val: 'sdi_origin',
            type: 'multi',
            width: 250,
          },
          {
            text: i18n.t('records.fields.destination'),
            value: 'sdi_destination',
            val: 'sdi_destination',
            type: 'multi',
            width: 250,
          },
          {
            text: i18n.t('records.fields.date'),
            value: 'record_date_time',
            val: 'record_date_time',
            type: 'date',
            width: 120,
          },
          {
            text: i18n.t('records.fields.process'),
            value: 'process_name',
            val: 'process',
            type: 'text',
            width: 150,
          },
          {
            text: i18n.t('general.fields.action'),
            value: 'action',
            width: 120,
            sortable: false,
            type: 'actions',
            align: 'center',
          },
        ],
        inspections: [
          {
            text: i18n.t('survey.fields.name'),
            value: 'name',
            val: 'name',
            width: 150,
          },
          {
            text: i18n.t('survey.fields.location'),
            value: 'location_name',
            val: 'locations',
            width: 340,
            sortable: false,
          },
          {
            text: i18n.t('survey.fields.person'),
            value: 'personInCharge',
            width: 170,
            val: 'personInCharge',
          },
          {
            text: i18n.t('survey.fields.date'),
            value: 'data',
            val: 'data',
            sortable: true,
            width: 120,
          },
          {
            text: i18n.t('survey.fields.score'),
            value: 'surveyPercentPunctuation',
            val: 'punctuation',
            sortable: false,
            width: 120,
          },
          {
            text: i18n.t('general.fields.action'),
            value: 'action',
            width: 120,
            sortable: false,
            align: 'center',
          },
        ],
        documents: [
          {
            text: i18n.t('documents.fields.authority'),
            value: 'authority_name',
            val: 'documentAuthority',
            type: 'text',
            width: 150,
            sortable: true,
          },
          {
            text: i18n.t('documents.fields.type'),
            value: 'type_name',
            type: 'text',
            sortable: false,
            width: 150,
          },
          {
            text: i18n.t('documents.fields.description'),
            value: 'description_name',
            type: 'text',
            sortable: false,
            width: 150,
          },
          {
            text: i18n.t('documents.fields.document_date'),
            value: 'emission',
            val: 'document_date',
            type: 'text',
            width: 130,
            sortable: true,
          },
          {
            text: i18n.t('documents.fields.expiration_date'),
            value: 'validade',
            val: 'expiration_date',
            type: 'text',
            width: 130,
            sortable: true,
          },
          {
            text: i18n.t('documents.fields.location'),
            value: 'storage_devices',
            type: 'list',
            sortable: false,
            width: 200,
          },
          {
            text: i18n.t('documents.fields.observations'),
            value: 'observations',
            type: 'text',
            width: 350,
            sortable: false,
          },
          {
            text: i18n.t('general.fields.action'),
            value: 'action',
            width: 120,
            sortable: false,
            type: 'actions',
            align: 'center',
          },
        ],
      },
      currentPage: 1,
      pageCount: 0,
      orientation: null,
    }),

    watch: {
      optionsR: {
        handler() {
          if (
            this.primaryLocation &&
            this.primaryLocation.length > 0 &&
            this.secondaryLocations &&
            this.secondaryLocations.length > 0
          ) {
            this.loading.records = true

            let filtersRecords = {
              pageableDTO: this.getPaginationByModule(
                this.optionsR,
                this.headers.records
              ),
              process_ids:
                this.process_ids.length > 0
                  ? this.getByProperty(_.cloneDeep(this.process_ids), 'id')
                  : [],
              sdi_destination_ids: this.getByProperty(
                _.cloneDeep(this.secondaryLocations),
                'storageDeviceId'
              ),
              company_id: this.profile.company_id,
              start_record_date_time:
                this.datetime.length > 0
                  ? [
                      moment(this.datetime[0]).format('YYYY-MM-DD') +
                        'T00:00:01',
                      moment(this.datetime[1]).format('YYYY-MM-DD') +
                        'T23:59:59',
                    ]
                  : [],
            }
            const CancelToken = axios.CancelToken
            let source = CancelToken.source()

            this.fetchRecords([filtersRecords, source, this.$toast]).then(
              () => {
                this.loading.records = false
              }
            )
          }
        },
        deep: true,
      },
      optionsI: {
        handler() {
          if (
            this.primaryLocation &&
            this.primaryLocation.length > 0 &&
            this.secondaryLocations &&
            this.secondaryLocations.length > 0
          ) {
            this.loading.inspections = true

            let filtersInspections = {
              pageableDTO: this.getPaginationByModule(
                this.optionsI,
                this.headers.inspections
              ),
              template_names:
                this.template_names.length > 0 ? this.template_names : [],
              sdi_origin_ids: this.getByProperty(
                _.cloneDeep(this.secondaryLocations),
                'storageDeviceId'
              ),
              company_id: this.profile.company_id,
              start_record_date_time:
                this.datetime.length > 0
                  ? [
                      moment(this.datetime[0]).format('YYYY-MM-DD') +
                        'T00:00:01',
                      moment(this.datetime[1]).format('YYYY-MM-DD') +
                        'T23:59:59',
                    ]
                  : [],
              min_punctuation: this.score[0],
              max_punctuation: this.score[1],
              surveyStateType: 'PUBLISHED',
            }

            this.fetchInspections([filtersInspections, this.$toast]).then(
              () => {
                this.loading.inspections = false
              }
            )
          }
        },
        deep: true,
      },
      optionsD: {
        handler() {
          if (
            this.primaryLocation &&
            this.primaryLocation.length > 0 &&
            this.secondaryLocations &&
            this.secondaryLocations.length > 0
          ) {
            this.loading.documents = true

            let filtersDocuments = {
              pageableDTO: this.getPaginationByModule(
                this.optionsD,
                this.headers.documents
              ),
              document_type_ids:
                this.document_type_ids.length > 0
                  ? _.cloneDeep(this.document_type_ids)
                  : [],
              document_description_ids:
                this.document_description_ids.length > 0
                  ? this.getByProperty(
                      _.cloneDeep(this.document_description_ids),
                      'id'
                    )
                  : [],
              sdi_destination_ids: _.cloneDeep(this.primaryLocation),
              storage_devices_ids: this.getByProperty(
                _.cloneDeep(this.secondaryLocations),
                'storageDeviceId'
              ),
              company_id: this.profile.company_id,
              start_record_date_time:
                this.datetime.length > 0
                  ? [
                      moment(this.datetime[0]).format('YYYY-MM-DD') +
                        'T00:00:01',
                      moment(this.datetime[1]).format('YYYY-MM-DD') +
                        'T23:59:59',
                    ]
                  : [],
              isActive: true
            }

            this.fetchDocuments([filtersDocuments, this.$toast]).then(() => {
              this.loading.documents = false
            })
          }
        },
        deep: true,
      },
    },

    computed: {
      ...mapGetters({
        score: 'inspections/score'
      }),
      recordSelected: {
        get() {
          return this.$store.getters['records/record']
        },
        set(val) {
          this.$store.commit('records/SET_RECORD', val)
        },
      },
      documentSelected: {
        get() {
          return this.$store.getters['documents/document']
        },
        set(val) {
          this.$store.commit('documents/SET_DOCUMENT', val)
        },
      },
      inspectionSelected: {
        get() {
          return this.$store.getters['inspections/inspection']
        },
        set(val) {
          this.$store.commit('inspections/SET_INSPECTION', val)
        },
      },

      ...mapGetters({
        recordsIds: 'reports/recordsIds',
        totalRecordsFromServer: 'reports/totalRecordsFromServer',
        inspectionsIds: 'reports/inspectionsIds',
        totalInspectionsFromServer: 'reports/totalInspectionsFromServer',
        documentsIds: 'reports/documentsIds',
        totalDocumentsFromServer: 'reports/totalDocumentsFromServer',
        allTemplates: 'survey/allTemplates',
        process: 'proccess/proccess',
        locationsList: 'authority/authoritiesLocations',
        allStoragesByUser: 'authority/authoritiesStoragesDevices',
        structuresDetails: 'records/structures',
        generalLoading: 'general/loading',
        documentTypes: 'document_type/documentTypes',
        documentDescriptions: 'document_description/documentDescriptions',
        pageEnum: 'reports/pageEnum',
        recordsCompanyById: 'records/recordsCompanyById',
        configUser: 'general/config',
        requireRules: 'general/requireRules',
        storages: 'storage/storagesList',
        locations: 'locations/locationsList',
        records: 'reports/records',
        inspections: 'reports/inspections',
        documents: 'reports/documents',
      }),
      getOriginStorageByLocationsFiltered() {
        return sortList.orderListByUppercase(
            this.getStorageByLocationsFilteredAction(this.primaryLocation, 'id'),
            ['name']
        )
      },
      ifRecordsFound() {
        return (
          this.totalRecordsFromServer ||
          this.totalInspectionsFromServer ||
          this.totalDocumentsFromServer
        )
      },
      isImageOrPdf() {
        return ['jpg', 'png', 'jpeg', 'pdf'].includes(
          this.documentSelected.file_extension
        )
      },
      // Exist structures
      existStructures() {
        return (
          this.recordSelected.recordStructureValuesOrganized.structures &&
          this.recordSelected.recordStructureValuesOrganized.structures.length >
            0
        )
      },
      // Exist groups
      existGroups() {
        return (
          this.recordSelected.recordStructureValuesOrganized.groups &&
          this.recordSelected.recordStructureValuesOrganized.groups.length > 0
        )
      },
      // Get structures
      getStructures() {
        return this.recordSelected.recordStructureValuesOrganized.structures
          ? this.recordSelected.recordStructureValuesOrganized.structures
          : []
      },
      // Get groups
      getGroups() {
        if (this.flagGroups) this.flagGroups = false
        return this.recordSelected.recordStructureValuesOrganized.groups
          ? this.recordSelected.recordStructureValuesOrganized.groups
          : []
      }
    },
    async created() {
      this.profile = JSON.parse(localStorage.getItem('profile'))
      await this.initialize()

      this.today = moment().format('YYYY-MM-DD')
    },
    methods: {
      ...mapActions({
        fetchAllTemplates: 'survey/fetchAllTemplates',
        fetchListDocumentDescriptionsByType:
          'document_description/fetchListDocumentDescriptionsByType',
        findRecordById: 'records/findRecordById',
        findDocumentById: 'documents/fetchDocumentById',
        fetchInspectionById: 'inspections/fetchInspectionById',
        fetchRecords: 'reports/fetchRecordsForGeneralReport',
        fetchInspections: 'reports/fetchInspectionsForGeneralReport',
        fetchDocuments: 'reports/fetchDocumentsForGeneralReport',
        fetchRangeScore: 'inspections/fetchRangeScore',
        getGeneralReportPDF: 'reports/getGeneralReportPDF',
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchStorageLocations: "storage/fetchFilteredStorages"
      }),
      async clearLocations() {
        this.$set(this, 'secondaryLocations', []);
        this.$store.commit('storage/RESTORE_STORAGE_LIST')
        this.$set(this, 'primaryLocation', []);

        await this.fetchLocations(null)

        this.checkboxState.primary = false
        this.checkboxState.secondary = true

        this.$store.commit('reports/SET_RECORDS_ORIGINAL_STATE')
        this.$store.commit('reports/SET_INSPECTIONS_ORIGINAL_STATE')
        this.$store.commit('reports/SET_DOCUMENTS_ORIGINAL_STATE')
      },
      async fetchLocations(e) {
        let byIds = false;

        if (!e) {
          byIds = true;
        }

        this.$set(this.loadings, 'origin', true);

        const filters = {
          stringFilter: e || "",
          ids: byIds ? this.primaryLocation : null,
          isActive: true
        }

        await this.fetchPrimaryLocations([filters, this.$toast]).finally(() => {
          this.$set(this.loadings, 'origin', false);
        })
      },
      async fetchStorages(e) {
        this.$set(this.loadings, 'destination', true);

        if (this.primaryLocation.length > 0 && typeof this.primaryLocation[0] === 'object') {
          this.primaryLocation = _.map(this.primaryLocation, l => l.locationId)
        }

        await this.fetchStorageLocations([{
          primaryLocationIds: this.primaryLocation,
          stringFilter: e || "",
          isActive: true
        }, this.$toast]).finally(() => {
          this.$set(this.loadings, 'destination', false);

          this.$store.commit('reports/SET_RECORDS_ORIGINAL_STATE')
          this.$store.commit('reports/SET_INSPECTIONS_ORIGINAL_STATE')
          this.$store.commit('reports/SET_DOCUMENTS_ORIGINAL_STATE')
        })
      },
      orderDatas(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      selectAll(type, length, value, checkbox, idField) {
        if (checkbox === false) {
          this.$nextTick(function () {
            switch (type) {
              case 'primary':
                this.checkboxState.primary = false
                break
              case 'secondary':
                this.checkboxState.secondary = false
                break
              case 'proccess':
                this.checkboxState.proccess = false
                break
              case 'document_type':
                this.checkboxState.document_type = false
                break
              case 'document_description':
                this.checkboxState.document_description = false
                break
              case 'name':
                this.checkboxState.name = false
                break
            }
          })

          if (["primary"].includes(type)) {
            this.$set(this, 'primaryLocation', [])
          }
          if (["secondary"].includes(type)) {
            this.$set(this, 'secondaryLocations', [])
          }

          if (['primary', 'secondary'].includes(type)) {
            this.fetchStorages(null)
          }

          return [];
        }
        let aux = [];
        if( length != null ) {
          if (typeof length[0] === 'object') {
            _.forEach(length, (object) => {
              if (!_.find(value, (o) => {
                return o[idField] === object[idField]
              }))
                value.push(object)
            })
          } else {
            _.forEach(length, (text) => {
              if (!value.includes(text))
                value.push(text)
            })
          }

          aux = value;
        }else {
          aux = value;
        }

        if (aux.length > 0 && typeof aux[0] === 'object') {
          if (["primary"].includes(type)) {
            this.primaryLocation = _.map(aux, s => s.locationId)
          }
          if (["secondary"].includes(type)) {
            this.secondaryLocations = _.map(aux, s => s.storageDeviceId)
          }
        }

        if (['primary', 'secondary'].includes(type)) {
          this.updateSecondaryLocations(aux)
          this.fetchStorages(null)
        }

        return aux;
      },
      checkState(prefix, total, values) {
        if (values.length === total.length) {
          switch (prefix) {
            case 'primary':
              this.checkboxState.primary = true
              break
            case 'secondary':
              this.checkboxState.secondary = true
              break
            case 'proccess':
              this.checkboxState.proccess = true
              break
            case 'document_type':
              this.checkboxState.document_type = true
              break
            case 'document_description':
              this.checkboxState.document_description = true
              break
            case 'name':
              this.checkboxState.name = true
              break
          }
        } else {
          switch (prefix) {
            case 'primary':
              this.checkboxState.primary = false
              break
            case 'secondary':
              this.checkboxState.secondary = false
              break
            case 'proccess':
              this.checkboxState.proccess = false
              break
            case 'document_type':
              this.checkboxState.document_type = false
              break
            case 'document_description':
              this.checkboxState.document_description = false
              break
            case 'name':
              this.checkboxState.name = false
              break
          }
        }
      },
      capitalizeFirstLetter(string) {
        string = string.toLowerCase()
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      async initialize() {
        await this.getAllTemplates()
        await this.fetchRangeScore([this.profile.company_id])
      },
      async getAllTemplates() {
        const filter = {
          surveyStateType: ['PUBLISHED', 'EDITING'],
        }
        await this.fetchAllTemplates([filter, this.$toast])
      },
      async searchData() {
        this.loadingReport = true

        this.selected = {
          records: [],
          inspections: [],
          documents: [],
        }

        this.optionsR.page = 1
        this.optionsI.page = 1
        this.optionsD.page = 1

        let data = {
          primaryLocation: _.cloneDeep(this.primaryLocation),
          secondaryLocations: this.getByProperty(
            _.cloneDeep(this.secondaryLocations),
            'storageDeviceId'
          ),
          documentType:
            this.document_type_ids.length > 0
              ? _.cloneDeep(this.document_type_ids)
              : [],
          documentDescriptions:
            this.document_description_ids.length > 0
              ? this.getByProperty(
                  _.cloneDeep(this.document_description_ids),
                  'id'
                )
              : [],
          process:
            this.process_ids.length > 0
              ? this.getByProperty(_.cloneDeep(this.process_ids), 'id')
              : [],
          templateNames:
            this.template_names.length > 0 ? this.template_names : [],
          date:
            this.datetime.length > 0
              ? [
                  moment(this.datetime[0]).format('YYYY-MM-DD') + 'T00:00:01',
                  moment(this.datetime[1]).format('YYYY-MM-DD') + 'T23:59:59',
                ]
              : [],
          evidences: _.cloneDeep(this.evidences),
        }

        this.filters.records = {
          pageableDTO: this.getPaginationByModule(
            this.optionsR,
            this.headers.records,
            'record_date_time'
          ),
          process_ids: data.process,
          sdi_destination_ids: data.secondaryLocations,
          company_id: this.profile.company_id,
          start_record_date_time: data.date,
        }

        this.filters.inspections = {
          pageableDTO: this.getPaginationByModule(
            this.optionsI,
            this.headers.inspections,
            'data'
          ),
          template_names: data.templateNames,
          sdi_origin_ids: data.secondaryLocations,
          company_id: this.profile.company_id,
          start_record_date_time: data.date,
          surveyStateType: 'PUBLISHED',
          min_punctuation: this.score[0],
          max_punctuation: this.score[1]
        }

        this.filters.documents = {
          pageableDTO: this.getPaginationByModule(
            this.optionsD,
            this.headers.documents,
            'document_date'
          ),
          document_type_ids: data.documentType,
          document_description_ids: data.documentDescription,
          sdi_destination_ids: data.primaryLocation,
          storage_devices_ids: data.secondaryLocations,
          company_id: this.profile.company_id,
          document_date: data.date,
          withExternalVisibility: true,
          isActive: true
        }

        const CancelToken = axios.CancelToken
        let source = CancelToken.source()

        let promises = []

        if (this.filter.records) {
          this.loading.records = true

          promises.push(
            this.fetchRecords([this.filters.records, source, this.$toast]).then(
              () => {
                this.loading.records = false
              }
            )
          )
        }
        if (this.filter.inspections) {
          this.loading.inspections = true

          promises.push(
            this.fetchInspections([this.filters.inspections, this.$toast]).then(
              () => {
                this.loading.inspections = false
              }
            )
          )
        }
        if (this.filter.documents) {
          this.loading.documents = true

          promises.push(
            this.fetchDocuments([this.filters.documents, this.$toast]).then(
              () => {
                this.loading.documents = false
              }
            )
          )
        }

        await Promise.all(promises).finally(() => {
          this.loadingReport = false
        })
      },
      getPaginationByModule(option, headers, sortField) {
        const direction =
          option.sortDesc.length === 0 || option.sortDesc[0] ? 'desc' : 'asc'
        const sort =
          option.sortBy.length === 0
            ? sortField
            : _.filter(headers, { value: option.sortBy[0] })[0].val
        return {
          page: option.page - 1,
          size: option.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }
      },
      // Get data more details dialog
      async showRecord(item) {
        this.loading.records = true
        await this.findRecordById([item.id, this.$toast]).finally(() => {
          this.loading.records = false
          this.showRecordDialog = true
        })
      },
      async showInspection(item) {
        this.loading.inspections = true
        await this.fetchInspectionById([item.id, this.$toast]).finally(() => {
          this.loading.inspections = false
          this.showInspectionDialog = true
        })
      },
      async showDocument(item) {
        this.loading.documents = true
        await this.findDocumentById([item.id, this.$toast]).finally(() => {
          this.loading.documents = false
          this.showDocumentDialog = true
        })
      },
      closeDialog() {
        this.orientation = null
        this.showModalExport = false
        this.exportLoading = false
      },
      closeDetails(type) {
        switch (type) {
          case 'record':
            this.$store.commit('records/SET_ORIGINAL_STATE_RECORD')
            this.showRecordDialog = false
            return
          case 'inspection':
            this.$store.commit('inspections/SET_ORIGINAL_STATE_INSPECTION')
            this.showInspectionDialog = false
            return
          case 'document':
            this.$store.commit('documents/SET_ORIGINAL_STATE_DOCUMENT')
            this.showDocumentDialog = false
            return
        }
      },
      checkBoxShow(module) {
        switch (module) {
          case 'records':
            this.showCheckBoxRecords = !this.showCheckBoxRecords
            if (!this.showCheckBoxRecords) this.selected.records = []

            return
          case 'inspections':
            this.showCheckBoxInspections = !this.showCheckBoxInspections
            if (!this.showCheckBoxRecords) this.selected.inspections = []

            return
          case 'documents':
            this.showCheckBoxDocuments = !this.showCheckBoxDocuments
            if (!this.showCheckBoxRecords) this.selected.documents = []

            return
        }
      },
      transformAnswers(question, answers) {
        if (STRUCTURE_TYPE.SIMPLE.includes(question.questionStructureType)) {
          let dateFormatted = (
            LanguajeService.getKey3(answers[0].answer) + '.000Z'
          ).slice(0, 19)
          return question.questionStructureType === 'DATA'
            ? moment(dateFormatted).format('DD / MM / YYYY')
            : question.questionStructureType === 'TIME'
            ? moment(dateFormatted).format('HH:mm')
            : question.questionStructureType === 'DATATIME'
            ? moment(dateFormatted).format('DD / MM / YYYY HH:mm')
            : LanguajeService.getKey3(answers[0].answer)
        }
        if (STRUCTURE_TYPE.MULTIPLE.includes(question.questionStructureType)) {
          let text = []
          _.forEach(answers, answer => {
            text.push(LanguajeService.getKey3(answer.answer))
          })
          return _.sortBy(text).join(', ')
        }
      },
      sectionAverage(section) {
        return section.questionSectionPercentPunctuation
      },
      dateRangeText() {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        let start = ''
        let end = ''
        let both = []
        if (this.datetime.length > 1) {
          if (this.datetime[0]) {
            ;[year, month, day] = this.datetime[0].split('-')
            start = moment(this.datetime[0]).format('YYYY-MM-DD') + 'T00:00:01'
          }
          if (this.datetime[1]) {
            ;[year2, month2, day2] = this.datetime[1].split('-')
            end = moment(this.datetime[1]).format('YYYY-MM-DD') + 'T23:59:59'
            both = [start, end]
          }
          this.date = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },
      getDateFormat(dateString) {
        return moment(new Date(dateString)).format('DD/MM/YYYY')
      },
      clearDate() {
        this.datetime = []
      },
      dialogClose() {
        this.orientation = null
        this.showDialog = false
        this.exportLoading = false
      },
      exportDialog() {
        this.showDialog = true
      },
      async exportRecords() {
        this.exportLoading = true

        const recordsFilter = _.pick(this.filters.records, [
          'pageableDTO',
          'process_ids',
        ])
        const inspectionsFilter = _.pick(this.filters.inspections, [
          'pageableDTO',
          'template_names',
        ])
        const documentsFilter = _.pick(this.filters.documents, [
          'pageableDTO',
          'document_type_ids',
          'document_description_ids',
          'withExternalVisibility',
          'isActive'
        ])

        const filters = {
          company_id: this.profile.company_id,
          language: LanguajeService.getLenguajeName(),
          orientation: this.orientation,
          recordFilter:
            this.selected.records && this.selected.records.length > 0
              ? {
                  record_ids: this.getByProperty(this.selected.records, 'id'),
                  withEvidences: this.evidences.process,
                }
              : {
                  ...recordsFilter,
                  withEvidences: this.evidences.process,
                },

          inspectionsFilter:
            this.selected.inspections && this.selected.inspections.length > 0
              ? {
                  inspections_ids: this.getByProperty(
                    this.selected.inspections,
                    'id'
                  ),
                  withEvidences: this.evidences.template,
                }
              : {
                  ...inspectionsFilter,
                  withEvidences: this.evidences.template,
                },

          documentFilter:
            this.selected.documents && this.selected.documents.length > 0
              ? {
                  document_ids: this.getByProperty(
                    this.selected.documents,
                    'id'
                  ),
                  withEvidences: this.evidences.documents,
                  withExternalVisibility: true
                }
              : {
                  ...documentsFilter,
                  withEvidences: this.evidences.documents
                },

          commonFilters: {
            sdi_ids: _.map(this.secondaryLocations).map(sdi => sdi.storageDeviceId),
            with_primary_evidences: this.evidences.primary,
            with_secondary_evidences: this.evidences.secondary,
            date:
              this.datetime.length > 0
                ? [
                    moment(this.datetime[0]).format('YYYY-MM-DD') + 'T00:00:01',
                    moment(this.datetime[1]).format('YYYY-MM-DD') + 'T23:59:59',
                  ]
                : [],
            records_export: this.filter.records,
            inspections_export: this.filter.inspections,
            documents_export: this.filter.documents
          },
        }

        await this.getGeneralReportPDF([filters, this.$toast]).finally(() => {
          this.orientation = null
          this.exportLoading = false
          this.showModalExport = false
        })
      },

      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },

      updateSecondaryLocations(event) {
        if (event.length > 0) {
          if (typeof event[0] === 'object') {
            const data = _.cloneDeep(this.getByProperty(event, 'id'))
            _.forEach(this.secondaryLocations, (storage, index) => {
              if (storage && !data.includes(storage.primaryLocationId)) {
                this.secondaryLocations.splice(index, 1)
              }
            })
          } else {
            _.forEach(this.secondaryLocations, (storage, index) => {
              if (storage && !event.includes(storage.primaryLocationId)) {
                this.secondaryLocations.splice(index, 1)
              }
            })
          }
        } else {
          this.secondaryLocations = []
        }
      },

      clearDocumentType() {
        this.document_description_ids = []
        this.checkboxState.document_type = false
      },
      getStorageByLocationsFilteredAction(data) {
        if (typeof data[0] === 'object') {
          const ids = this.getByProperty(data, 'id')
          return ids && ids.length > 0
              ? _.filter(this.allStoragesByUser, storage => {
                if (ids.includes(storage.primaryLocationId)) {
                  return storage
                }
              })
              : []
        } else {
          return data && data.length > 0
              ? _.filter(this.allStoragesByUser, storage => {
                if (data.includes(storage.primaryLocationId)) {
                  return storage
                }
              })
              : []
        }
      },
      getDescriptionsType(data) {
        this.documentDescriptionFiltered(this.getByProperty(data, 'id'))

        return this.getByProperty(data, 'id')
      },
      async documentDescriptionFiltered(type) {
        this.loadingDescription = true
        if (!(type && type.length > 0)) {
          this.document_description_ids = []
          this.loadingDescription = false
          return
        }

        await this.fetchListDocumentDescriptionsByType([
          this.profile.company_id,
          type,
          this.$toast,
        ]).finally(() => {
          this.loadingDescription = false
        })
      },

      zoomPlus() {
        if (this.imageWidth + 50 >= 1000) {
          return
        }
        return (this.imageWidth += 50)
      },

      zoomLess() {
        if (this.imageWidth - 50 <= 400) {
          return
        }
        return (this.imageWidth -= 50)
      },
      nextPage() {
        if (this.pageCount >= this.currentPage + 1) this.currentPage++
      },

      backPage() {
        if (this.currentPage > 1) this.currentPage--
      },

      getByProperty(array, property) {
        return _.map(array, property)
      },
      getDetailsMainData(type) {
        switch (type) {
          case 'record_date_time':
            return this.getDateFormat(this.recordsCompanyById[type])
          default:
            return this.recordsCompanyById[type]
        }
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
    },

    destroyed() {
      this.$store.commit('reports/SET_RECORDS_ORIGINAL_STATE')
      this.$store.commit('reports/SET_INSPECTIONS_ORIGINAL_STATE')
      this.$store.commit('reports/SET_DOCUMENTS_ORIGINAL_STATE')
    },
  }
</script>

<style scoped lang="scss">
  .text-filter-title {
    background: rgba(76, 229, 207, 0.1);
    color: #03ac8c;
    padding: 10px;
    border-radius: 0.1em;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
  }
</style>
